import React, { Component } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import dictionary from "./dictionary"
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi"

class Block extends Component {
  render() {
    const { column, serviceId, serviceList, item, style } = this.props
    let ServiceList = []
    if (serviceList) {
      ServiceList = serviceList
    }

    if (serviceId && (dictionary || {})[serviceId]) {
      ServiceList = (dictionary || {})[serviceId]
    }
    const ServiceContent = ServiceList.slice(0, item)
    const serviceCategories = [
      "ALL",
      ...new Set(
        ServiceContent.map(service => service.category).filter(
          service => !!service
        )
      ),
    ]

    return (
      <>
        <div className="designer-portfolio-area ptb--60 bg_color--1">
          <div className="wrapper plr--70 plr_sm--30 plr_md--30">
            <Tabs>
              <div className="row text-center">
                <div className="col-lg-12">
                  <div className="tablist-inner">
                    <TabList className="pv-tab-button text-center mt--0">
                      {serviceCategories.map(group => (
                        <Tab>
                          <span>{group}</span>
                        </Tab>
                      ))}
                    </TabList>
                  </div>
                </div>
              </div>

              {serviceCategories.map((group, i) => {
                return (
                  <TabPanel className="row" key={i}>
                    {ServiceContent.filter(
                      s => s.category === group || group === "ALL"
                    ).map((val, i) => {
                      return (
                        <div className={`${column}`} key={i}>
                          <a href={`${val.link || "/#"}`}>
                            <div
                              className={`service service__style--${
                                style || "2"
                              }`}
                            >
                              <div className="icon">{val.icon}</div>
                              <div className="content">
                                {val.category ? (
                                  <span className="category">
                                    {val.category}
                                  </span>
                                ) : null}

                                <h3 className="title">{val.title}</h3>
                                <p>{val.description}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      )
                    })}
                  </TabPanel>
                )
              })}
            </Tabs>
          </div>
        </div>
      </>
    )
  }
}
export default Block
