import React from "react"

import { ConcreteTruck, EnviornmentMonitor, PipeValve } from "../icons"

const Descriptors = [
  {
    icon: <ConcreteTruck width={84} height={84} />,
    title: "Construction Machinery Solutions",
    description:
      "Supply of Machinery Solutions such as Construction Machinery, Concrete Equipments, Material Handling Equipments, Industrial Air Compressors, and Power Generators",
  },
  {
    icon: <EnviornmentMonitor width={84} height={84} />,
    title: "Environmental Monitoring",
    description:
      "Delivering end-to-end Solutions for Environmental Monitoring which includes Air, Weather, Water Management, Solar  and Telemetry",
  },
  {
    icon: <PipeValve width={84} height={84} />,
    title: "MEP Supplies",
    description:
      "Supply of Plumbing & Electrical Products, Industrial Fire Protection Products, HVAC Products and HVAC Controls",
  },
]

export default props => {
  return (
    <React.Fragment>
      {Descriptors.map((val, i) => (
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <div className="service service__style-2">
            <div className="icon">{val.icon}</div>
            <div className="content">
              <h4 className="title">{val.title}</h4>
              <p>{val.description}</p>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}
