import React from "react"
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi"

import {
  Bulldozer,
  PowerGenerator,
  Roller,
  ConcreteMixer,
  MaterialHandling,
  Engine,
  CuttingMachine,
  WaterManagement,
  WeatherStation,
  RadiationDetector,
  Telemetry,
  Plumbing,
  AirCondition,
  CoolingSystem,
} from "../icons"

export default {
  home: [
    {
      category: "CONSTRUCTION",
      icon: <PowerGenerator />,
      title: "Air & Power Solutions",
      description:
        "We supply Air Compressors, Generators, Lighting Tower, Pneumatic Jack Hammers and Scalling Hammers",
    },
    {
      category: "CONSTRUCTION",
      icon: <ConcreteMixer />,
      title: "Concrete & Surfacing Solutions",
      description:
        "Range includes all kinds of Concrete Mixers, Vibrators, Poker Shafts, Floor Polishing Machines and Scarfiers",
    },
    {
      category: "CONSTRUCTION",
      icon: <MaterialHandling />,
      title: "Material Handling",
      description:
        "Covers the logistics management of construction projects; provides excavators, handlers, cranes, forklift trucks, lifting devices and converyor systems",
    },
    {
      category: "ENVIRONMENT",
      icon: <WeatherStation />,
      title: "Environmental Monitoring",
      description:
        "Module includes the processes and activities involved in characterize and monitoring of Air Quality and Pollution and Meteorology",
    },
    {
      category: "ENVIRONMENT",
      icon: <WaterManagement />,
      title: "Hydrometry",
      description:
        "Includes Surface & Ground Water Level & Quality, Water Flow and Automatic Sampling",
    },
    {
      category: "MEP",
      icon: <Plumbing />,
      title: "Plumbing Products",
      description:
        "Range includes from commercial plumbing valves to all types of plumbing requirements",
    },
    {
      category: "CONSTRUCTION",
      icon: <Roller />,
      title: "PMV Solutions",
      description:
        "Includes an entire family of products convering all aspects of PMV such as Compactors, Steel Cutting/Bending Machines, Rammers, Asphalt & Concrete Cutters, Stone, Tile & Wood Cutters, etc.",
    },

    {
      category: "CONSTRUCTION",
      icon: <Engine />,
      title: "Engine & Drive Units",
      description:
        "Supply of Gasoline/Deisel Engines, Power Vibration Shafts and Submersible Pumps with portable Drive Units",
    },
    {
      category: "CONSTRUCTION",
      icon: <CuttingMachine />,
      title: "Cutting Blades & Coring Bits",
      description: "Includes Wet & Dry Coring Bits and Diamond Cutting Blades",
    },

    {
      category: "ENVIRONMENT",
      icon: <RadiationDetector />,
      title: "Health & Safety",
      description:
        "Supply of Gas Detection, Radiation Detectors and Contamination control Products",
    },
    {
      category: "ENVIRONMENT",
      icon: <Telemetry />,
      title: "Telemetry & Telecommunication",
      description:
        "Solution to provide real time data and monitoring of the equipments and providing the capacity of remote configuration, control and data logging",
    },

    {
      category: "MEP",
      icon: <AirCondition />,
      title: "HVAC Products",
      description:
        "Supply of Vibration Isolators, Grooved Fittings, Expansion Joints, Water Hammer Arrestors,Dielectric Union, Grills and Diffusers",
    },
    {
      category: "MEP",
      icon: <CoolingSystem />,
      title: "District Cooling",
      description:
        "Supply of Vibration Isolators, Grooved Fittings, Expansion Joints, Water Hammer Arrestors,Dielectric Union, Grills and Diffusers",
    },
  ],
}
