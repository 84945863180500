import React from "react"
import Slider from "react-slick"
import { slideSlick } from "../config/options"

const SlideList = [
  {
    textPosition: "text-left",
    bgImage: "bg_image--main",
    category: "TEKMENS",
    title: "An Engineering Company",
    description: "Expertise in Engineering, Construction, Environment, and MEP",
    buttonText: "Our Business Sectors",
    buttonLink: "/sectors",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--construction",
    category: "Machinery",
    title: "Construction",
    description:
      "Concrete | Surface Preparation | Compaction | Power Solution | Material Handling",
    buttonText: "TEKMENS CONSTRUCTION",
    buttonLink: "/sectors/construction",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--environment",
    category: "MONITORING",
    title: "Environment & Health",
    description: "Air | Weather | Water | Solar | Telemetry",
    buttonText: "TEKMENS ENVIRONMENT",
    buttonLink: "/sectors/environment",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--mep",
    category: "PRODUCTS",
    title: "MEP Products",
    description: "HVAC | PVC | Switches & Cables",
    buttonText: "TEKMENS MEP",
    buttonLink: "/sectors/mep",
  },
]

export default props => {
  return (
    <>
      {/* Start Slider Area   */}
      <div className="slider-wrapper">
        <div className="slider-activation">
          <Slider className="rn-slick-dot dot-light" {...slideSlick}>
            {SlideList.map((value, index) => (
              <div
                className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                key={index}
                data-black-overlay="8"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title theme-gradient">
                            {value.title}
                          </h1>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--2 btn-solid"
                              href={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* End Slider Area   */}
    </>
  )
}
